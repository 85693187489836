<template>
  <div class="p-20">
    <div class="bg-white p-20">

      <h3 class="mb-30">webSocket配置</h3>
      <el-form ref="form" :model="form" label-width="200px">
        <el-form-item label="数据发送间隔">
          <el-input-number :controls="false" v-model="form.time"></el-input-number>
          <span class="ml-10">秒</span>
        </el-form-item>
        <el-form-item label="单次传输数据量">
          <el-input-number :controls="false" v-model="form.count"></el-input-number>
          <span class="ml-10">条</span>
        </el-form-item>
        <el-form-item label="数据分">
          <el-input-number :controls="false" v-model="form.second"></el-input-number>
          <span class="ml-10">次传输</span>
        </el-form-item>
        <el-form-item label="一次性查询数据量">
          <el-input-number :controls="false" v-model="form.sqlCount"></el-input-number>
          <span class="ml-10">条</span>
        </el-form-item>
        <el-form-item label="查询">
          <el-input-number :controls="false" v-model="form.sqlTime"></el-input-number>
          <span class="ml-10">分钟内的数据</span>
        </el-form-item>
        <!-- <el-form-item label="查询的数据库">
          <el-input-number :controls="false" v-model="form.db"></el-input-number>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getWSConfig, editWSConfig } from '@/apis/authManage/webSocket'
export default {
  data () {
    return {
      form: {
        time: '',
        count: '',
        second: '',
        sqlCount: '',
        sqlTime: '',
        db: ''
      }
    }
  },
  created () {
    getWSConfig().then((res) => {
      if (res.code == 200) {
        this.form = Object.assign({}, this.form, res.data)
      }
    })
  },
  methods: {
    onSubmit () {
      editWSConfig(this.form).then((res) => {
        if (res.code == 200) {
          this.$notify.success({ title: '成功', message: '操作成功！' })
        } else {
          this.$notify.error({ title: '失败', message: res.msg })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
span {
  color: #606266;
}
</style>
